<template>
  <div
    :class="{ 'floating-navbar': true, 'space-between': spaceBetween }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FloatingNavbar',
  props: {
    spaceBetween: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.floating-navbar {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 708px;
  width: 100%;
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid $gray-ultralight;
  box-shadow: 0px 2px 4px rgba(81, 107, 152, 0.16);
  border-radius: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;

  &.space-between {
    justify-content: space-between;
  }

  @media (max-width: 740px) {
    width: calc(100% - 32px);
  }

  &.hidden {
    display: none;
  }

  button {
    width: 130px;
    height: 35px;
    border-radius: 17.5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    box-sizing: border-box;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    outline: none;

    @include respond(mobile) {
      width: 120px;
      height: 33px;
    }

    &.cancel {
      background: $white;
      border: 1px solid #2b2323;
      color: $black;
      margin-left: 14px;

      &:hover {
        border: 1px solid $gray;
        transition: all 0.2s ease-in-out;
      }
    }

    &.continue {
      color: $white;
      background: $blue;
      box-shadow: 3px 3px 10px -1px rgba(11, 27, 102, 0.3);
      border: none;
      margin-right: 14px;

      &:hover {
        background-color: $blue-hover;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
</style>
