<template>
  <div>
    <b-loading
      v-if="loading"
      :is-full-page="true"
      :active="true"
    />
    <template v-else>
      <navigation-bar
        :background="'white'"
        :link="{ name: 'proyectos' }"
        label="Proyectos"
      />
      <div class="container is-widescreen page-bottom-padding-extra">
        <div class="wrapper">
          <div class="columns is-centered">
            <div class="column standard-column">
              <h1 class="community-title">
                {{ projectData.name }}
              </h1>
              <div
                v-if="projectData.main_image"
                class="img-featured"
              >
                <cld-image
                  cloud-name="relevant"
                  :public-id="projectData.main_image.public_id"
                  class="project-cover"
                  crop="scale"
                  secure="true"
                >
                  <cld-transformation radius="8" />
                </cld-image>
              </div>
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-if="projectData.description"
                class="project-description"
                v-html="projectData.description"
              />
              <!--eslint-enable-->
              <div class="project-info-panel">
                <div class="date">
                  <section>
                    <h3>Fecha de inicio</h3>
                    <span class="value">
                      {{ formattedStartDate }}
                    </span>
                  </section>
                  <section>
                    <h3>Fecha de finalizacion</h3>
                    <span class="value">
                      {{ formattedFinishDate }}
                    </span>
                  </section>
                </div>
                <div
                  v-if="projectData.client"
                  class="organizer"
                >
                  <h3>Organizador</h3>
                  <span class="value">
                    {{ projectData.client }}
                  </span>
                </div>
              </div>
              <div
                v-if="projectData.extra_info_title || projectData.extra_info"
                class="project-extra-info"
              >
                <h2 v-if="projectData.extra_info_title">
                  {{ projectData.extra_info_title }}
                </h2>
                <!-- eslint-disable vue/no-v-html -->
                <div
                  v-if="projectData.extra_info"
                  v-html="projectData.extra_info"
                />
                <!--eslint-enable-->
              </div>
            </div>
          </div>
        </div>
        <floating-navbar>
          <button
            class="continue"
            @click="startParticipatingInProject"
          >
            <template v-if="participantNotStarted">
              Comenzar
            </template>
            <template v-else>
              Ingresar
            </template>
          </button>
        </floating-navbar>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { CldImage, CldTransformation } from 'cloudinary-vue'

import { formatDate } from '@/dates'
import FloatingNavbar from '@/components/floating-navbar/FloatingNavbar'
import NavigationBar from '@/components/NavigationBar'
import router from '../../../router' // eslint-disable-line import/no-cycle

export default {
  name: 'ParticipationViewContentContainer',
  components: {
    CldImage,
    CldTransformation,
    FloatingNavbar,
    NavigationBar
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('participationProject', ['projectData']),
    ...mapGetters('participationParticipant', ['participantInProgress', 'participantNotStarted']),
    formattedStartDate() {
      return formatDate(this.projectData.start_date)
    },
    formattedFinishDate() {
      return formatDate(this.projectData.finish_date)
    }
  },
  methods: {
    async startParticipatingInProject() {
      const goToProjectHome = () => {
        router.push({
          name: 'project-home',
          params: {
            identifier: this.projectData.identifier
          }
        })
      }

      if (this.participantInProgress) {
        goToProjectHome()
      } else if (this.projectData.relevant_tnc_required || this.projectData.project_tnc_required) {
        router.push({
          name: 'participation-terms-and-conditions',
          params: {
            identifier: this.projectData.identifier
          }
        })
      } else {
        this.loading = true

        await this.$store.dispatch('participationParticipant/startParticipatingInProject')

        if (this.participantInProgress) {
          goToProjectHome()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../../scss/community.scss';
</style>
