<template>
  <participation-view-content-container
    :current-stage="currentStage"
    :grey-background="isCommunityProject"
  >
    <!-- Only rendered in community type projects -->
    <community-project-detail v-if="isCommunityProject" />
  </participation-view-content-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import sharedConstants from '@/shared/constants.json'
import CommunityProjectDetail from '@/components/participation/communities/CommunityProjectDetail.vue'
import ParticipationViewContentContainer from '@/components/participation/ParticipationViewContentContainer.vue'
import participationStages from '@/components/participation/participationStages'

export default {
  name: 'ProjectDetail',
  components: {
    CommunityProjectDetail,
    ParticipationViewContentContainer
  },
  computed: {
    ...mapGetters('participationProject', ['isCommunityProject']),
    ...mapState('participationParticipant', ['participantData']),
    ...mapState('participationProject', ['projectData']),
    participationInProgress() {
      return this.participantData && (this.participantData.status === sharedConstants.PARTICIPANT_STATUS.IN_PROGRESS)
    }
  },
  created() {
    this.currentStage = participationStages.projectDetail
  }
}
</script>
